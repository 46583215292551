.accommodation-teaser {
  .card {
    overflow: hidden;
  }

  .card-body {
    margin-left: 250px;

    h2 {
      color: $text-color;
    }

    small {
      color: $text-color-alt;
    }
  }

  .card-image {
    position: absolute;
    height: 100%;
    width: 250px;
    left: 0;
    top: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .image-placeholder {
      width: 100%;
      height: 100%;
      background-color: $form-element-background;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 25px;
      }
    }
  }

  .accommodation-teaser-body {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr 0.5fr;
    gap: 0px 0px;
    grid-template-areas:
    "teaser-name teaser-status"
    "teaser-linked .";
    height: 100%;
  }

  .teaser-name {
    grid-area: teaser-name;
  }

  .teaser-status {
    grid-area: teaser-status;
    text-align: right;
  }
  .teaser-linked {
    grid-area: teaser-linked;
  }
}