.c-property-list {
    .c-property {
    }
}

.c-property {
    margin-bottom: 0.5rem;
    &__label {
        font-weight: bold;
    }
    &__value {

    }
}
