$stepper-dot-height: 17px;
$stepper-dot-height-active: 30px;
$stepper-line-height: 7px;
$stepper-primary-color: $blue-jeans;
$stepper-secondary-color: $blue-jeans-50;

.stepper-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        @media (max-width: 768px) {
            font-size: 12px;
        }

        &::before {
            position: absolute;
            content: "";
            border-bottom: $stepper-line-height solid $stepper-secondary-color;
            width: 100%;
            top: calc((#{$stepper-dot-height} / 2) - (#{$stepper-line-height} / 2));
            left: -50%;
            z-index: 2;
        }

        &::after {
            position: absolute;
            content: "";
            border-bottom: $stepper-line-height solid $stepper-secondary-color;
            width: 100%;
            top: calc((#{$stepper-dot-height} / 2) - (#{$stepper-line-height} / 2));
            left: 50%;
            z-index: 2;
        }

        &:first-child {
            &::before {
                content: none;
            }
        }

        &:last-child {
            &::after {
                content: none;
            }
        }

        &.active, &.completed {
            .step-dot {
                background-color: $stepper-primary-color;
                width: $stepper-dot-height-active;
                height: $stepper-dot-height-active;
                margin-top: calc(((#{$stepper-dot-height-active} - #{$stepper-dot-height}) / 2) * -1);
            }

            .step-name {
                margin-top: 0;
                color: $stepper-primary-color;
            }
        }

        &.completed {
            &::before {
                position: absolute;
                content: "";
                border-bottom: $stepper-line-height solid $stepper-primary-color;
                width: 100%;
                top: calc((#{$stepper-dot-height} / 2) - (#{$stepper-line-height} / 2));
                left: 50%;
                z-index: 3;
            }
        }

        .step-name {
            margin-top: calc(((#{$stepper-dot-height-active} - #{$stepper-dot-height}) / 2));
            font-weight: bolder;
            color: $stepper-secondary-color;
            font-size: 14px;
        }

        .step-dot {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $stepper-dot-height;
            height: $stepper-dot-height;
            border-radius: 50%;
            background: $stepper-secondary-color;
            margin-bottom: 6px;
        }

    }
}