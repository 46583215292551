body {
    main {
        padding-top: 55px;

        @include media-breakpoint-up(lg) {
            padding-top: 65px;
        }

        .toolbar {
            margin: 0 -15px;

            .icon-arrow-left .path1:before {
                color: #3f4254 !important;
                opacity: 1 !important;
            }

            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.toolbar-fixed {
            main {
                padding-top: 140px;
            }
        }
    }
}

.hidden {
    display: none;
}

.rotate-180 {
    transform: rotate3d(0, 0, 1, 180deg);
}

.total-calculation {
    border-top: 1px solid black;
    width: 300px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.bg-blue {
    background-color: $background;
}

.text-gray {
    color: $space-cadet-50;
}

.text-green {
    color: $mantis;
}

.text-orange {
    color: $coral;
}

.text-blue {
    color: $blue-jeans;
}

.text-red {
    color: $wild-watermelon;
}

.text-yellow {
    color: $naples-yellow-125;
}

.text-purple {
    color: $lavender-purple;
}

.government-logo-wrapper {
    z-index: -100;

    .government-logo {
        margin-right: 2rem;
        margin-bottom: 2rem;

        img {
            width: 150px;
        }
    }
}

.impersonator-bar {
    position: fixed;
    background-color: rgba(252, 237, 173,.6);
    z-index: 100;
    min-height: 40px;
    height: auto;
    width: 100%;
    display: block;
    line-height: 40px;
    text-align: center;
    bottom: 0;
    backdrop-filter: blur(7px);
}
