.table-icon {
  i {
    font-size: 16px;
    color: $blue-jeans;
    margin-right: .5em;
  }
}

.table-empty-state-message {
  font-size: 1.4rem;
  color: $santas-gray;
}

.table-finalise {
  border: 1px solid $athens-gray;
  border-radius: 5px;

  .table {
    margin: 0;

    i {
      font-style: italic;
    }

    thead {
      tr {
        background-color: #f9f9f9;
      }
    }

    tr.border-bottom {
      td {
        border-bottom: 1px solid $athens-gray;
      }
    }

    td {
      padding: 1em;
    }
  }
}