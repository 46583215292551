.profile-image-placeholder {
  background-color: $blue-jeans;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  transition: border-radius .325s ease-in;

  &:hover {
    border-radius: 14px;
  }
}