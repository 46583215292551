.characteristics-table {
  .label {
    width: 35%;
    vertical-align: middle;
  }

  .unit-field {

    input {
      padding-right: 50px;
      background-image: none !important;
    }

    .text-field-unit {
      position: absolute;
      top: 0;
      right: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: $text-color;
    }
  }

}