.dynamicCostsFormWidget {

  .table-responsive {
    overflow: inherit;
  }

  .table-finalise .table {
      i {
        font-style: normal;
      }
  }

  .multiselect {
    width: 250px !important;
    position: relative;
    height: 46px;

    .multiselect-clear, .multiselect-caret {
      position: absolute;
      right: 0;
      top: calc(50% - 7px);
    }

    .multiselect-clear + .multiselect-caret {
      display: none;
    }

    .multiselect-option.is-selected {
      background: $blue-jeans;
    }
  }

  .btn-add-row {
    border: 1px solid $blue-jeans !important;
    background-color: transparent;
    color: $blue-jeans;
    display: flex;
    align-items: center;
    font-weight: bold;

    &:hover {
      background-color: $blue-jeans-10;
    }

    i {
      color: $blue-jeans;
      font-size: 8px;
    }
  }
}