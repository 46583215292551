.file-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px 8px;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 30px 20px;
    border: 1px solid $blue-jeans;
    border-radius: .475rem;
    color: $blue-jeans;
    position: relative;
    aspect-ratio: 1;

    .file-type {
      color: $blue-jeans;
    }

    .file-options {
      .btn-remove {
        position: absolute;
        top: 6px;
        right: 6px;
        z-index: 10;
        background-color: $wild-watermelon;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        i {
          color: $just-white;
        }
      }

      .download-mark {
        background-color: darken($blue-jeans, 20%);
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 10;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        i {
          color: $just-white;
        }
      }
    }

    &:hover {
      color: darken($blue-jeans, 20%);

      .file-type {
        color: darken($blue-jeans, 20%);
      }

      .btn-remove, .download-mark {
        display: flex;
      }
    }
  }

  .file-image {
    border-radius: 0.68rem;
    position: relative;
    cursor: move;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      .btn-handle, .btn-remove-upload {
        opacity: 1;
      }
    }

    .btn-handle, .btn-remove-upload {
      background-color: $just-white;
      color: $space-cadet-75;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      opacity: 0;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      margin: 0;

      i {
        padding: 0;
        margin: 0;
      }
    }

    .btn-remove-upload {
      margin-top: 8px;
      
      i {
        color: $wild-watermelon;
      }

      &:hover {
        background-color: $wild-watermelon-10;
      }
    }

    .btn-handle {
      position: absolute;
      top: calc(50% - (38px / 2) - 15px);
      left: calc(50% - 15px);

    }

    .image-container {
      border: 3px solid transparent;
      border-radius: 0.68rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }

    .cover-tag {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 10;
      padding: 0 8px;
      background-color: $blue-jeans;
      border-radius: 12px;
      width: auto;
      height: 25px;
      display: none;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: .5px;
      color: $just-white;
    }

    .btn-remove {
      position: absolute;
      top: 6px;
      right: 6px;
      z-index: 10;
      background-color: $wild-watermelon;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        color: $just-white;
      }
    }

    &:first-of-type {
      .cover-tag {
        display: flex;
      }
    }

    &.selected {
      .image-container {
        border: 5px solid $blue-jeans;
      }
    }

    &.file-image-select {
      cursor: pointer;

      .cover-tag {
        display: none;

        &.selected {
          display: flex;
        }
      }
    }

    &:hover {
      .image-container {
        border: 3px solid $blue-jeans;
      }

      .btn-remove {
        display: flex;
      }
    }
  }
}