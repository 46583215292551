.alert {
  &.alert-optional {
    background-color: $just-white;
    border: 1px solid $blue-jeans-50;

    .btn-optional {
      color: $blue-jeans;

      &:hover {
        background-color: $blue-jeans-25;
      }
    }
  }
}