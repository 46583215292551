.dropzone {
  .dropzone-select {
    border: 1px dashed #009ef7;
    background-color: #ecf8ff;
    padding: 1.5rem 1.75rem;
    border-radius: .475rem !important;
  }
}

.draggable {
  cursor: move;
}

.js_remove-zone {
  border: 2px dashed $athens-gray;
  border-radius: 8px;
  height: 75px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .file-image {
    display: none;
  }

  i {
   font-size: 20px;
  }
}