.mini-menu {
  position: absolute;
  z-index: 50;

  top: calc(100% + 8px);
  right: 0;

  width: 300px;
  height: auto;
  padding: 30px;

  background-color: $just-white;
  border-radius: 20px;
  border: 1px solid $athens-gray-10;
  box-shadow: 0px 10px 30px 0px rgba(82,63,105,.1);

  text-align: left;
}