.year-card {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1em .8em;
  border-bottom: 1px solid $athens-gray;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba($athens-gray, .2);
  }

  .year, small {
     padding-left: 35px;
  }

  .year {
    font-size: 30px;
    line-height: 30px;
    color: $text-color;
  }

  small {
    color: rgba($text-color, .3);
  }

  .arrow {
    position: absolute;
    top: 1em;
    right: .8em;
  }

  .check {
    position: absolute;
    top: calc(50% - 12.5px);
    left: .8em;
    border-radius: 50%;
    border: 1px solid;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;

    i {
      font-size: 10px;
    }
  }

  &.active {
    .year {
      color: $blue-jeans;
    }
  }

  &.success {
    .check {
      border-color: $mantis;
      color: $mantis;

      i {
        color: $mantis;
      }
    }
  }

  &.warning {
    .check {
      border-color: $wild-watermelon;
      color: $wild-watermelon;

      i {
        color: $mantis;
      }
    }
  }
}