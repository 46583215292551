.stat-option {
  color: $coral !important;
  background-color: $coral-10 !important;
}

.stat-approvalPending {
  color: $lavender-purple !important;
  background-color: $lavender-purple-10 !important;
}

.stat-finalising {
  color: $blue-jeans !important;
  background-color: $blue-jeans-10 !important;
}

.stat-planned {
  color: rgb(40,167,69) !important;
  background-color: rgb(232,255,243) !important;
}
