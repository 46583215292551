.textfield-with-icon {
  position: relative;

  input {
    padding-right: 40px;
  }

  i {
    position: absolute;
    font-size: 14px;
    top: calc(50% - 7px);
    right: 20px;
    width: 1px;
  }
}

.textfield-with-front-icon {
  position: relative;

  input {
    padding-left: 40px;
  }

  i {
    position: absolute;
    font-size: 14px;
    top: calc(50% - 7px);
    left: 20px;
    width: 1px;
  }
}