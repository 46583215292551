h1, h2, h3, h3, h4, h5 {
  color: $text-color;
}

.container {
  max-width: 1340px !important;
}

.card-toolbar.floating {
  position: absolute;
  top: 0;
  right: 0;
}

.nav {
  .nav-item {
    &:first-of-type {
      .btn {
        margin-left: 0 !important;
      }
    }
  }
}

.fv-row, .form-check-custom {
  margin-bottom: 1em !important;
}

.btn-primary {
  background-color: $blue-jeans !important;

  &:hover {
    background-color: darken($blue-jeans, 10%) !important;
  }
}

.btn-orange {
  background-color: $coral;
  color: white !important;

  &:hover {
    background-color: darken($coral, 10%) !important;
  }
}

iframe {
  width: 100%;
}

.select2-container .select2-selection--single {
  height: auto;
}

.swal2-close, .swal2-default-outline {
  &:focus {
    box-shadow: none !important;
  }
}

.fc .fc-popover {
  z-index: 1000 !important;
}

.cke_top {
  background-color: $form-element-background !important;
  border-radius: 10px  !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom: none !important;
  margin: 0 -8px !important;
}

.cke_bottom {
  background-color: $form-element-background !important;
  border: 0 !important;
  border-radius: 8px !important;
  padding: 4px 16px !important;
  margin: 0 -8px !important;
}

.cke_toolgroup {
  margin: 0 !important;
}

.cke {
  border-color: $form-element-background !important;
  border-radius: 10px !important;
  border-top: 0 !important;
  margin: 0 8px;
}

.cke_button {
  border-radius: 8px !important;

  &:hover {
    border: 1px solid transparent !important;
    background-color: #ebebeb !important;
    cursor: pointer;
  }
}
.blockui {
  .blockui-overlay {
    background-color: rgba(255,255,255,.5);
  }
}

.modal {
  background-color: rgba($just-white, .4);
}

.toast {
  opacity: 1 !important;
}

.form-control-lg {
  padding: 0.55rem 0.75rem;

  @include media-breakpoint-up(lg) {
    padding: 0.825rem 1.5rem;
  }
}

th a.sortable{
  color: unset;
}
