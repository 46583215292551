.color-select {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    color: $gray-600;
  }

  .color-block {
    border-radius: 6px;
    width: 50px;
    height: 50px;
    display: block;
    margin-right: .5em;
    border: 2px solid $athens-gray;

    &:before {
      position: absolute;
      display: block;
      content: ' ';
      width: 46px;
      height: 46px;
      border-radius: 4px;
      border: 2px solid $just-white;
    }

    &:after {
      content: "\f26e";
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -.125em;
      -webkit-font-smoothing: antialiased;

      color: $just-white;
      justify-content: center;
      display: none;
      align-items: center;
      height: 100%;
      font-size: 20px;
    }
  }

  input:checked ~ .checkmark {
    font-weight: bold;
  }

  input:checked ~ .checkmark {
    .color-block {
      border: 2px solid $blue-jeans;

      &:after {
        display: flex;
      }
    }
  }

  &.white {
    .color-block {
      background-color: white;
    }
  }

  &.green {
    .color-block {
      background-color: $mantis;
    }
  }

  &.gray {
    .color-block {
      background-color: black;
    }
  }

  &.yellow {
    .color-block {
      background-color: $naples-yellow-125;
    }
  }
}


