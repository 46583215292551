.c-admin-content {
    .table {
        th {
            a {
                color: unset;
            }
        }
        thead {
            border-bottom: 2px solid #DDDDDD;
            th {
                font-weight: bold;
            }
        }
        tbody {
            th {
                a:hover {
                    text-decoration: underline !important;
                }
            }

            th, td {
                border-right: 1px solid #DDDDDD;

                &:last-child {
                    border-right: unset;
                }
            }
        }
        &.table-striped {
            tr:first-child, th:first-child, td:first-child {
                padding-left: 0.5rem;
            }
            tr:first-child, th:first-child, td:first-child {
                padding-left: 0.5rem;
            }
        }
    }
}
