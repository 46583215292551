.check-in-check-out-times {
    td {
        &.buffer {
            vertical-align: middle !important;
            text-align: center;

            &.warning {
                color: red;
            }
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            .form-select {
                max-width: 115px;

                &:first-of-type {
                    margin-right: 5px;
                }

                &:last-of-type{
                    margin-left: 5px;
                }
            }
        }
    }
}
