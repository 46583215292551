.booking-event-asset {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
  }

  a {
    margin-left: 8px;
  }
}