header.header {
  background-color: $just-white;
  position: fixed;
  width: 100%;
}

.header-menu.drawer {
  flex-direction: column;
  padding-top: 3rem;

  .menu {
    margin: 0 !important;
  }
}