.legend {
  .legend-item {
    padding: 4px 0;
    position: relative;
    display: flex;
    align-items: center;
  }

  .legend-event {
    &.booking_slot {
      &:before {
        background-color: $mantis;
      }
    }

    &.booked {
      &:before {
        background-color: $blue-jeans;
      }
    }

    &.booking-linked {
      &:before {
        background-color: $endeavour;
      }
    }

    &.booking_to_approve {
      &:before {
        background-color: $lavender-purple;
      }
    }

    &.booking_in_option {
      &:before {
        background-color: $coral;
      }
    }

    &.unavailable {
      &:before {
        background-color: $space-cadet-75;
      }
    }

    &.partially_booked {
      &:before {
        background-color: $naples-yellow-125;
      }
    }

    &:before {
      content: ' ';
      display: block;
      width: 20px;
      height: 15px;
      border-radius: 6px;
      margin-right: 8px;
    }
  }

  .legend-bg-event {
    &.unavailable {
      &:before {
        background-color: #EEF2F5;
      }
    }

    &.weekend {
      &:before {
        background-color: #fdf9ef;
      }
    }

    &.vacation {
      &:before {
        background-color: #f9e3e3;
      }
    }

    &:before {
      content: ' ';
      display: block;
      width: 20px;
      height: 15px;
      margin-right: 8px;
    }
  }
}