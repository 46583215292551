.c-admin-content {
    .card {
        margin: 1rem 1rem 3rem 1rem;
    }

    .card-header {
        justify-content: space-between;
        align-items: center;

        .card-toolbar {
            margin-left: auto;
        }
    }
}

.card-header {
    row-gap: 10px;

    @media (max-width: 599px) {
        justify-content: center !important;
    }

    .card-more-info {
        display: flex;
        align-items: center;
        column-gap: 30px;

        p {
            max-width: 500px;
            margin: 0;

            @media (max-width: 1240px) {
                max-width: 100%;
            }

            @media (max-width: 599px) {
                text-align: center;
            }
        }

        @media (max-width: 599px) {
            flex-direction: column;
            row-gap: 10px;
        }
    }
}
