.domain-context-widget {
  cursor: pointer;
  user-select: none;

  .domain-context-menu {
    position: absolute;
    background-color: $just-white;
    padding: 16px;
    border-radius: 16px;
    margin-top: 8px;
    box-shadow: 0 0 50px 0 rgba(82,63,105,0.1);
  }

  .domain-context-item {
    margin-bottom: 4px;
    color: $text-color;

    &:hover {
      color: $blue-jeans;
    }
  }
}