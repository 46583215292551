.conflict {

  .btn-re-add, .btn-dismiss, .fa-spinner {
    display: none;
  }

  &.loading {
    .fa-spinner {
      display: inline-block;
    }

    .btn-re-add, .btn-dismiss {
      display: none !important;
    }
  }

  &.new {
    .bullet {
      background-color: $wild-watermelon;
    }

    .btn-dismiss {
      display: inline-block;
    }
  }

  &.dismissed {
    .btn-re-add {
      display: inline-block;
    }
  }
}