.vertical-stepper-component {
  .vertical-stepper-item {
    margin-top: 13px;
    margin-bottom: 13px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;

      .vertical-stepper-content {
        &:before {
          display: none;
        }
      }
    }

    &.current {
      .vertical-stepper-header {
        .vertical-stepper-symbol {
          background-color: $coral;
        }

        label {
          color: $space-cadet-75;
        }
      }

      .vertical-stepper-content {
        padding-bottom: 100px;
      }
    }

    &.completed {
      .vertical-stepper-header {
        .vertical-stepper-symbol {
          background-color: $coral;
        }

        label {
          color: $coral;
        }
      }

      .vertical-stepper-content {
        &:before {
          background-color: $coral;
        }
      }
    }

    .vertical-stepper-header {
      display: flex;
      justify-content: space-between;

      .vertical-stepper-symbol {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 30px;
        height: 30px;

        border-radius: 5px;
        background-color: $space-cadet-25;
        color: $just-white;
        font-weight: bold;
        font-size: 18px;
      }

      label {
        margin-left: 18px;

        color: $space-cadet-50;
        font-weight: bold;
        font-size: 18px;
      }

      .vertical-stepper-prev-btn {
        background-color: $space-cadet-25;
        color: $space-cadet-75;
      }
    }

    .vertical-stepper-content {
      position: relative;
      min-height: 50px;
      width: 100%;
      margin-top: 13px;
      padding-left: calc(30px + 18px);

      &:before {
        position: absolute;
        top: 0;
        left: 14px; // (30px - 2px) / 2
        content: ' ';
        display: block;

        width: 2px;
        height: 100%;

        background-color: $space-cadet-25;
      }

      .vertical-stepper-next-btn {
        background-color: $coral;
        color: $just-white;
      }
    }
  }
}