#dailyOvernightStatisticsWidget {
  .grid-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 16px 16px;
    grid-template-areas: "title min-30 plus-30";

    .title {
      grid-area: title;
    }

    .min-30 {
      grid-area: min-30;
    }

    .plus-30 {
      grid-area: plus-30;
    }

    .grid-span-2 {
      grid-column: 2 / span 2;
    }
  }

  .statistics-headers {
    .min-30, .plus-30 {
      text-align: center;
    }
  }

  .switch-toggle-expand {
    background-color: $form-element-background;
    border-radius: 10px;
    padding: 2px;
    display: inline-flex;
    user-select: none;
    font-weight: bold;
    font-size: 16px;

    button {
      border: none;
      background-color: transparent;
      color: $text-color-alt;
      padding: 4px 10px;
      border-radius: 9px;
      font-weight: 500;
      font-size: 13px;

      &.active {
        background-color: white;
        color: $coral;
      }
    }
  }

  .daily-overnight-element {
      border-bottom: 1px solid $athens-gray;
      padding: 30px 0;

      &:last-of-type {
        border-bottom: none;
      }

      h4:first-letter {
        text-transform: capitalize;
      }

      .form-check-input:checked {
        background-color: $coral;
        border-color: $coral;
      }

      .locked {
        opacity: .6;

        &.calculation-sum-number-field {
          &:after {
            background-color: $santas-gray !important;
          }
        }

        input {
          background-color: $form-element-background !important;
        }
      }

      .sub-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.unaknowledged {
          display: none;
        }

        b {
          color: $santas-gray;
        }

        i {
          font-style: italic;
        }

        p {
          color: $coral;
          font-size: 11px;
        }
      }

      .calculation-sum-number-field {
        position: relative;
        margin-top: 16px;

        &:before {
          content: ' ';
          position: absolute;
          top: -32px;
          left: calc(50% - 1px);
          display: block;
          height: 32px;
          width: 2px;
          border-left: 2px dashed $athens-gray;
        }

        &:after {
          content: '+';
          position: absolute;
          top: calc(-32px + 9px);
          left: calc(50% - 7px);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $coral;
          color: $just-white;
          width: 14px;
          height: 14px;
          font-size: 15px;
          font-weight: bold;
          border-radius: 2px;
        }
      }
    }
}
