// overwritting defauft hidden class
.flatpickr-day.hidden {
  display: block;
}

.calendar-multi-month {
  display: block !important;
  position: relative;

  .flatpickr-calendar {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .flatpickr-input {
    visibility: hidden;
  }

  &.calendar-multi-month-visible-input {
    .flatpickr-input {
      visibility: visible;
    }
  }

  .flatpickr-calendar, .flatpickr-days {
    width: 620px !important;
  }

  .flatpickr-day.startRange {
    border-radius: 50px;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      height: calc(100% + 2px);
      width: calc(50% + 2px);
      background: #CEEBFF;
      top: -1px;
      right: -6px;
      z-index: -1;
    }
  }

  .flatpickr-day.inRange {
    background: #CEEBFF;
    box-shadow: -5px 0 0 #CEEBFF, 5px 0 0 #CEEBFF;
    border-color: #CEEBFF;
  }

  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    -webkit-box-shadow: -2px 0 0 #CEEBFF, 5px 0 0 #CEEBFF;
    box-shadow: -2px 0 0 #CEEBFF, 5px 0 0 #CEEBFF;
  }

  .flatpickr-day.endRange {
    border-radius: 50px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      height: calc(100% + 2px);
      width: calc(50% + 2px);
      background: #CEEBFF;
      top: -1px;
      left: -6px;
      z-index: -1;
    }
  }

  .dayContainer {
    &:first-of-type {
      border-right: 1px solid $santas-gray;
    }
  }
}

.fc-event-time {
  //display: none;
}

.fc-daygrid-event, .fc-daygrid-dot-event, .fc-daygrid-dot-event:hover {
  color: $just-white !important;
  cursor: pointer;

  &.booking_slot {
    background-color: $mantis;
    border-color: $mantis;
  }

  &.booking {
    background-color: $blue-jeans;
    border-color: $blue-jeans;
  }

  &.linked-booking {
    background-color: $endeavour;
    border-color: $endeavour;
  }

  &.booking_to_approve {
    background-color: $lavender-purple;
    border-color: $lavender-purple;
  }

  &.booking_in_option {
    background-color: $coral;
    border-color: $coral;
  }

  &.unavailable {
    background-color: $space-cadet-75;
    border-color: $space-cadet-75;
  }

  &.booked_ical {
    background-color: $space-cadet-75;
    border-color: $space-cadet-75;
  }

  &.partially_booked {
    background-color: $naples-yellow-125;
    border-color: $naples-yellow-125;
    .fc-event-main {
      color: $space-cadet-75;
    }
  }

  .fc-daygrid-event-dot {
    display: none;
  }
}

.fc-bg-event {
  &.midweek {
    background-color: $just-white !important;
    opacity: 1 !important;
  }
  &.weekend {
    background-color: #fdf9ef !important;
    opacity: 1 !important;
  }
  &.vacation {
    background-color: #f9e3e3 !important;
    opacity: 1 !important;
  }

  &.has-conflict {
    &:before {
      content: "\f33a";
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -.125em;
      -webkit-font-smoothing: antialiased;
      color: $wild-watermelon;
      position: absolute;
      top: 4px;
      left: 4px;
    }
  }

  &.unavailable {
    background-color: transparent !important;
    opacity: 1 !important;
  }
}

.timepickr.flatpickr-input {
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.noCalendar {
  box-shadow: none !important;
  width: 100%;

  .flatpickr-time {
    border: 1px solid $athens-gray;
  }
}


// calender modal

.swal2-popup {
  padding: 0;
  overflow: hidden;
}

.swal2-html-container {
  margin: 0 !important;
  padding: 0;
  max-height: none !important;

  .event-title {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid #E9EBEA;
    margin-bottom: 0 !important;
  }

  .event-content {
    padding: 20px;
  }

  .event-date-title {
    text-transform: uppercase;
    display: block;
    font-weight: bold;
  }

  .event-reservation-link {
    padding: 20px;
    background-color: #FAFCFE;
    text-align: right;

    a {
      margin: 0 !important;
    }
  }
}

.months-container {
  flex-wrap: wrap;
  padding: 20px;
}

.day.day-start.day-half .day-content,
.day.day-end.day-half .day-content {
  background: linear-gradient(135deg, transparent 47%, rgb(120, 120, 120) 49%, transparent 51%, transparent);
}

.year-calendar-modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .modal-title {
    margin: 0;
  }

  .close {
    cursor: pointer;
  }

  .modal-body {
    padding-bottom: 10px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }

  .btn {
    cursor: pointer;
    padding: 8px 16px;
    margin-left: 8px;
    border-radius: 5px;
  }

  .btn-primary {
    background-color: #007bff;
    color: #fff;
  }

  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
}

.form-select-wrapper {
  width: 300px;
}

#loaderWrapper {
  position: relative;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  border-radius: calc(0.475rem - 1px) calc(0.475rem - 1px) 0 0
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #ff8360;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}