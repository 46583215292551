.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 560px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 740px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 560px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 740px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 940px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.flex-align-last-right {
  margin-left: auto;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4c97f4;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(14.0973684211, 110.2157894737, 229.4026315789) !important;
}

.text-secondary {
  color: #6c757d;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(72.5407725322, 78.5858369099, 83.9592274678) !important;
}

.text-success {
  color: #28a745;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(25.2173913043, 105.2826086957, 43.5) !important;
}

.text-info {
  color: #17a2b8;
}

a.text-info:hover, a.text-info:focus {
  color: rgb(14.5, 102.1304347826, 116) !important;
}

.text-warning {
  color: #ffc107;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(185.5, 139.125, 0) !important;
}

.text-danger {
  color: #dc3545;
}

a.text-danger:hover, a.text-danger:focus {
  color: rgb(167.4810126582, 29.0189873418, 42.2848101266) !important;
}

.text-light {
  color: #f8f9fa;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(203.375, 210.75, 218.125) !important;
}

.text-dark {
  color: #343a40;
}

a.text-dark:hover, a.text-dark:focus {
  color: rgb(17.7068965517, 19.75, 21.7931034483) !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.multiselect {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
  border-radius: var(--ms-radius, 4px);
  background: var(--ms-bg, #fff);
  font-size: var(--ms-font-size, 1rem);
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + var(--ms-py, 0.5rem) * 2);
}

.multiselect.is-open {
  border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
}

.multiselect.is-open-top {
  border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
}

.multiselect.is-disabled {
  cursor: default;
  background: var(--ms-bg-disabled, #f3f4f6);
}

.multiselect.is-active {
  box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, rgba(16, 185, 129, 0.1882352941));
}

.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background: transparent;
  line-height: var(--ms-line-height, 1.375);
  padding-left: var(--ms-px, 0.875rem);
  padding-right: calc(1.25rem + var(--ms-px, 0.875rem) * 3);
  box-sizing: border-box;
  max-width: 100%;
}

.multiselect-placeholder {
  color: var(--ms-placeholder-color, #9ca3af);
}

.multiselect-single-label-text {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.multiselect-search {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  outline: none;
  box-sizing: border-box;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  background: var(--ms-bg, #fff);
  border-radius: var(--ms-radius, 4px);
  padding-left: var(--ms-px, 0.875rem);
}

.multiselect-search::-webkit-search-cancel-button, .multiselect-search::-webkit-search-decoration, .multiselect-search::-webkit-search-results-button, .multiselect-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-tags {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  margin: var(--ms-tag-my, 0.25rem) 0 0;
  padding-left: var(--ms-py, 0.5rem);
  align-items: center;
}

.multiselect-tag {
  background: var(--ms-tag-bg, #10b981);
  color: var(--ms-tag-color, #fff);
  font-size: var(--ms-tag-font-size, 0.875rem);
  line-height: var(--ms-tag-line-height, 1.25rem);
  font-weight: var(--ms-tag-font-weight, 600);
  padding: var(--ms-tag-py, 0.125rem) 0 var(--ms-tag-py, 0.125rem) var(--ms-tag-px, 0.5rem);
  border-radius: var(--ms-tag-radius, 4px);
  margin-right: var(--ms-tag-mx, 0.25rem);
  margin-bottom: var(--ms-tag-my, 0.25rem);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.multiselect-tag.is-disabled {
  padding-right: var(--ms-tag-px, 0.5rem);
  background: var(--ms-tag-bg-disabled, #9ca3af);
  color: var(--ms-tag-color-disabled, #fff);
}

.multiselect-tag-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--ms-tag-remove-py, 0.25rem) var(--ms-tag-remove-px, 0.25rem);
  margin: var(--ms-tag-remove-my, 0) var(--ms-tag-remove-mx, 0.125rem);
  border-radius: var(--ms-tag-remove-radius, 4px);
}

.multiselect-tag-remove:hover {
  background: rgba(0, 0, 0, 0.062745098);
}

.multiselect-tag-remove-icon {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: currentColor;
  opacity: 0.8;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
}

.multiselect-tags-search-wrapper {
  display: inline-block;
  position: relative;
  margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.multiselect-tags-search-copy {
  visibility: hidden;
  white-space: pre-wrap;
  display: inline-block;
  height: 1px;
  width: 100%;
}

.multiselect-tags-search {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  appearance: none;
}

.multiselect-tags-search::-webkit-search-cancel-button, .multiselect-tags-search::-webkit-search-decoration, .multiselect-tags-search::-webkit-search-results-button, .multiselect-tags-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-spinner {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: var(--ms-spinner-color, #10b981);
  width: 1rem;
  height: 1rem;
  z-index: 10;
  margin: 0 var(--ms-px, 0.875rem) 0 0;
  -webkit-animation: multiselect-spin 1s linear infinite;
  animation: multiselect-spin 1s linear infinite;
  flex-shrink: 0;
  flex-grow: 0;
}

.multiselect-clear {
  padding: 0 var(--ms-px, 0.875rem) 0 0;
  position: relative;
  z-index: 10;
  opacity: 1;
  transition: 0.3s;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
}

.multiselect-clear:hover .multiselect-clear-icon {
  background-color: var(--ms-clear-color-hover, #000);
}

.multiselect-clear-icon {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  background-color: var(--ms-clear-color, #999);
  display: inline-block;
  transition: 0.3s;
}

.multiselect-caret, .multiselect-clear-icon {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  width: 0.625rem;
  height: 1.125rem;
}

.multiselect-caret {
  transform: rotate(0deg);
  transition: transform 0.3s;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  background-color: var(--ms-caret-color, #999);
  margin: 0 var(--ms-px, 0.875rem) 0 0;
  position: relative;
  z-index: 10;
  flex-shrink: 0;
  flex-grow: 0;
  pointer-events: none;
}

.multiselect-caret.is-open {
  transform: rotate(180deg);
  pointer-events: auto;
}

.multiselect-dropdown {
  position: absolute;
  left: calc(var(--ms-border-width, 1px) * -1);
  right: calc(var(--ms-border-width, 1px) * -1);
  bottom: 0;
  transform: translateY(100%);
  border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #d1d5db);
  margin-top: calc(var(--ms-border-width, 1px) * -1);
  max-height: 15rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  background: var(--ms-dropdown-bg, #fff);
  display: flex;
  flex-direction: column;
  border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
  outline: none;
}

.multiselect-dropdown.is-top {
  transform: translateY(-100%);
  top: var(--ms-border-width, 1px);
  bottom: auto;
  flex-direction: column-reverse;
  border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
}

.multiselect-dropdown.is-hidden {
  display: none;
}

.multiselect-options {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: var(--ms-max-height, 10rem);
}

.multiselect-options.is-top {
  flex-direction: column-reverse;
}

.multiselect-group {
  padding: 0;
  margin: 0;
}

.multiselect-group-label {
  padding: var(--ms-group-label-py, 0.3rem) var(--ms-group-label-px, 0.75rem);
  font-size: 0.875rem;
  font-weight: 600;
  background: var(--ms-group-label-bg, #e5e7eb);
  color: var(--ms-group-label-color, #374151);
  cursor: default;
  line-height: var(--ms-group-label-line-height, 1.375);
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.multiselect-group-label.is-pointable {
  cursor: pointer;
}

.multiselect-group-label.is-pointed {
  background: var(--ms-group-label-bg-pointed, #d1d5db);
  color: var(--ms-group-label-color-pointed, #374151);
}

.multiselect-group-label.is-selected {
  background: var(--ms-group-label-bg-selected, #059669);
  color: var(--ms-group-label-color-selected, #fff);
}

.multiselect-group-label.is-disabled {
  background: var(--ms-group-label-bg-disabled, #f3f4f6);
  color: var(--ms-group-label-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-group-label.is-selected.is-pointed {
  background: var(--ms-group-label-bg-selected-pointed, #0c9e70);
  color: var(--ms-group-label-color-selected-pointed, #fff);
}

.multiselect-group-label.is-selected.is-disabled {
  background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
  color: var(--ms-group-label-color-selected-disabled, #d1fae5);
}

.multiselect-group-options {
  padding: 0;
  margin: 0;
}

.multiselect-option {
  padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
  font-size: var(--ms-option-font-size, 1rem);
  line-height: var(--ms-option-line-height, 1.375);
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.multiselect-option.is-pointed {
  background: var(--ms-option-bg-pointed, #f3f4f6);
  color: var(--ms-option-color-pointed, #1f2937);
}

.multiselect-option.is-selected {
  background: var(--ms-option-bg-selected, #10b981);
  color: var(--ms-option-color-selected, #fff);
}

.multiselect-option.is-disabled {
  background: var(--ms-option-bg-disabled, #fff);
  color: var(--ms-option-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-option.is-selected.is-pointed {
  background: var(--ms-option-bg-selected-pointed, #26c08e);
  color: var(--ms-option-color-selected-pointed, #fff);
}

.multiselect-option.is-selected.is-disabled {
  background: var(--ms-option-bg-selected-disabled, #87dcc0);
  color: var(--ms-option-color-selected-disabled, #d1fae5);
}

.multiselect-no-options, .multiselect-no-results {
  padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
  color: var(--ms-empty-color, #4b5563);
}

.multiselect-fake-input {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  border: 0;
  padding: 0;
  font-size: 0;
  outline: none;
}

.multiselect-fake-input:active, .multiselect-fake-input:focus {
  outline: none;
}

.multiselect-spacer {
  display: none;
}

@-webkit-keyframes multiselect-spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes multiselect-spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?9jvmw4");
  src: url("../../fonts/icomoon.eot?9jvmw4#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?9jvmw4") format("truetype"), url("../../fonts/icomoon.woff?9jvmw4") format("woff"), url("../../fonts/icomoon.svg?9jvmw4#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.svg-icon {
  width: 20px;
  height: 20px;
  fill: #999baa;
}

[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.big-blue {
  font-size: 16px;
  color: #57b8ff;
}

.icon-20 {
  font-size: 20px;
}

.icon-voucher:before {
  content: "\e93b";
}

.icon-cancel:before {
  content: "\e93a";
}

.icon-pen:before {
  content: "\e939";
}

.icon-sleeping-bag:before {
  content: "\e936";
}

.icon-night:before {
  content: "\e937";
}

.icon-house-tent:before {
  content: "\e938";
}

.icon-bell:before {
  content: "\e935";
}

.icon-exclamation:before {
  content: "\e934";
}

.icon-user:before {
  content: "\e92f";
}

.icon-cog:before {
  content: "\e930";
}

.icon-dots:before {
  content: "\e931";
}

.icon-transaction:before {
  content: "\e932";
}

.icon-star-outline:before {
  content: "\e933";
}

.icon-arrow-down .path1:before {
  content: "\e900";
  color: rgb(153, 155, 170);
  opacity: 0.64;
}

.icon-arrow-down .path2:before {
  content: "\e901";
  margin-left: -1.5em;
  color: rgb(26, 30, 64);
}

.icon-arrow-left {
  position: relative;
  padding-right: 3px;
}
.icon-arrow-left .path1 {
  position: absolute;
  left: 3px;
  top: 0;
}
.icon-arrow-left .path1:before {
  content: "\e902";
  color: rgb(153, 155, 170);
  opacity: 0.64;
}
.icon-arrow-left .path2:before {
  content: "\e903";
  color: rgb(26, 30, 64);
}

.icon-arrow-right {
  position: relative;
  padding-left: 10px;
  top: 0;
}
.icon-arrow-right .path1 {
  position: absolute;
  left: 0;
}
.icon-arrow-right .path1:before {
  content: "\e904";
  color: rgb(153, 155, 170);
  opacity: 0.64;
}
.icon-arrow-right .path2 {
  margin-left: -0.5em;
}
.icon-arrow-right .path2:before {
  content: "\e905";
  color: rgb(26, 30, 64);
}
.icon-arrow-right.primary .path1:before {
  content: "\e904";
  color: #57b8ff;
  opacity: 0.64;
}
.icon-arrow-right.primary .path2:before {
  content: "\e905";
  color: #57b8ff;
}
.icon-arrow-right.white .path1:before {
  content: "\e904";
  color: #ffffff;
  opacity: 0.64;
}
.icon-arrow-right.white .path2:before {
  content: "\e905";
  color: #ffffff;
}

.icon-arrow-up .path1:before {
  content: "\e906";
  color: rgb(153, 155, 170);
  opacity: 0.64;
}

.icon-arrow-up .path2:before {
  content: "\e907";
  margin-left: -1.5em;
  color: rgb(26, 30, 64);
}

.icon-backpack:before {
  content: "\e928";
}

.icon-bed:before {
  content: "\e909";
}

.icon-brochure:before {
  content: "\e90a";
}

.icon-budget:before {
  content: "\e90b";
}

.icon-calendar:before {
  content: "\e90c";
}

.icon-camp-fire:before {
  content: "\e90d";
}

.icon-camp-ground:before {
  content: "\e916";
}

.icon-caret-down:before {
  content: "\e90f";
}

.icon-caret-right:before {
  content: "\e910";
}

.icon-check:before {
  content: "\e911";
}

.icon-close:before {
  content: "\e912";
}

.icon-combo:before {
  content: "\e913";
}

.icon-cook-fire:before {
  content: "\e914";
}

.icon-email:before {
  content: "\e915";
}

.icon-family-walk:before {
  content: "\e92b";
}

.icon-filter:before {
  content: "\e917";
}

.icon-heart:before {
  content: "\e918";
}

.icon-hike:before {
  content: "\e919";
}

.icon-hotel:before {
  content: "\e91a";
}

.icon-house:before {
  content: "\e91b";
}

.icon-internet:before {
  content: "\e91c";
}

.icon-location:before {
  content: "\e91d";
}

.icon-map:before {
  content: "\e91e";
}

.icon-meal:before {
  content: "\e91f";
}

.icon-negative:before {
  content: "\e920";
}

.icon-options:before {
  content: "\e921";
}

.icon-people:before {
  content: "\e922";
}

.icon-phone:before {
  content: "\e923";
}

.icon-plus:before {
  content: "\e924";
}

.icon-positive:before {
  content: "\e925";
}

.icon-recognition:before {
  content: "\e926";
}

.icon-rooms:before {
  content: "\e927";
}

.icon-search-house:before {
  content: "\e908";
}

.icon-search:before {
  content: "\e929";
}

.icon-share:before {
  content: "\e92a";
}

.icon-sign-tent:before {
  content: "\e90e";
}

.icon-star:before {
  content: "\e92c";
}

.icon-stove:before {
  content: "\e92d";
}

.icon-tent:before {
  content: "\e92e";
}

.icon-file:before {
  content: "\e92e";
}

.form-check-label {
  flex-basis: 90%;
}

span::-webkit-scrollbar, ol::-webkit-scrollbar, ul::-webkit-scrollbar, pre::-webkit-scrollbar, div::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

span::-webkit-scrollbar-thumb, ol::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
  background-color: #ff8360 !important;
}

span::-webkit-scrollbar-track, ol::-webkit-scrollbar-track, ul::-webkit-scrollbar-track, pre::-webkit-scrollbar-track, div::-webkit-scrollbar-track {
  background-color: #ffeee9 !important;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}
.scrollable::-webkit-scrollbar {
  width: 0.2rem;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: #ff8360;
  border-radius: 2px;
}
.scrollable::-webkit-scrollbar-track {
  background-color: #ffeee9;
  border-radius: 2px;
}

body main {
  padding-top: 55px;
}
@media (min-width: 992px) {
  body main {
    padding-top: 65px;
  }
}
body main .toolbar {
  margin: 0 -15px;
}
body main .toolbar .icon-arrow-left .path1:before {
  color: #3f4254 !important;
  opacity: 1 !important;
}
@media (min-width: 992px) {
  body main .toolbar {
    margin: 0;
  }
}
@media (min-width: 992px) {
  body.toolbar-fixed main {
    padding-top: 140px;
  }
}

.hidden {
  display: none;
}

.rotate-180 {
  transform: rotate3d(0, 0, 1, 180deg);
}

.total-calculation {
  border-top: 1px solid black;
  width: 300px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.bg-blue {
  background-color: #EDF7FF;
}

.text-gray {
  color: #999BAA;
}

.text-green {
  color: #7dce82;
}

.text-orange {
  color: #ff8360;
}

.text-blue {
  color: #57b8ff;
}

.text-red {
  color: #ff606a;
}

.text-yellow {
  color: #f6cc16;
}

.text-purple {
  color: #8C76B2;
}

.government-logo-wrapper {
  z-index: -100;
}
.government-logo-wrapper .government-logo {
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.government-logo-wrapper .government-logo img {
  width: 150px;
}

.impersonator-bar {
  position: fixed;
  background-color: rgba(252, 237, 173, 0.6);
  z-index: 100;
  min-height: 40px;
  height: auto;
  width: 100%;
  display: block;
  line-height: 40px;
  text-align: center;
  bottom: 0;
  backdrop-filter: blur(7px);
}

h1, h2, h3, h3, h4, h5 {
  color: #1a1e40;
}

.container {
  max-width: 1340px !important;
}

.card-toolbar.floating {
  position: absolute;
  top: 0;
  right: 0;
}

.nav .nav-item:first-of-type .btn {
  margin-left: 0 !important;
}

.fv-row, .form-check-custom {
  margin-bottom: 1em !important;
}

.btn-primary {
  background-color: #57b8ff !important;
}
.btn-primary:hover {
  background-color: rgb(36, 162.4464285714, 255) !important;
}

.btn-orange {
  background-color: #ff8360;
  color: white !important;
}
.btn-orange:hover {
  background-color: rgb(255, 91.2264150943, 45) !important;
}

iframe {
  width: 100%;
}

.select2-container .select2-selection--single {
  height: auto;
}

.swal2-close:focus, .swal2-default-outline:focus {
  box-shadow: none !important;
}

.fc .fc-popover {
  z-index: 1000 !important;
}

.cke_top {
  background-color: #f5f8fa !important;
  border-radius: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom: none !important;
  margin: 0 -8px !important;
}

.cke_bottom {
  background-color: #f5f8fa !important;
  border: 0 !important;
  border-radius: 8px !important;
  padding: 4px 16px !important;
  margin: 0 -8px !important;
}

.cke_toolgroup {
  margin: 0 !important;
}

.cke {
  border-color: #f5f8fa !important;
  border-radius: 10px !important;
  border-top: 0 !important;
  margin: 0 8px;
}

.cke_button {
  border-radius: 8px !important;
}
.cke_button:hover {
  border: 1px solid transparent !important;
  background-color: #ebebeb !important;
  cursor: pointer;
}

.blockui .blockui-overlay {
  background-color: rgba(255, 255, 255, 0.5);
}

.modal {
  background-color: rgba(255, 255, 255, 0.4);
}

.toast {
  opacity: 1 !important;
}

.form-control-lg {
  padding: 0.55rem 0.75rem;
}
@media (min-width: 992px) {
  .form-control-lg {
    padding: 0.825rem 1.5rem;
  }
}

th a.sortable {
  color: unset;
}

header.header {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
}

.header-menu.drawer {
  flex-direction: column;
  padding-top: 3rem;
}
.header-menu.drawer .menu {
  margin: 0 !important;
}

.table-icon i {
  font-size: 16px;
  color: #57b8ff;
  margin-right: 0.5em;
}

.table-empty-state-message {
  font-size: 1.4rem;
  color: #999baa;
}

.table-finalise {
  border: 1px solid #e5e6e9;
  border-radius: 5px;
}
.table-finalise .table {
  margin: 0;
}
.table-finalise .table i {
  font-style: italic;
}
.table-finalise .table thead tr {
  background-color: #f9f9f9;
}
.table-finalise .table tr.border-bottom td {
  border-bottom: 1px solid #e5e6e9;
}
.table-finalise .table td {
  padding: 1em;
}

.year-card {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1em 0.8em;
  border-bottom: 1px solid #e5e6e9;
}
.year-card:last-of-type {
  border-bottom: none;
}
.year-card:hover {
  background-color: rgba(229, 230, 233, 0.2);
}
.year-card .year, .year-card small {
  padding-left: 35px;
}
.year-card .year {
  font-size: 30px;
  line-height: 30px;
  color: #1a1e40;
}
.year-card small {
  color: rgba(26, 30, 64, 0.3);
}
.year-card .arrow {
  position: absolute;
  top: 1em;
  right: 0.8em;
}
.year-card .check {
  position: absolute;
  top: calc(50% - 12.5px);
  left: 0.8em;
  border-radius: 50%;
  border: 1px solid;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
}
.year-card .check i {
  font-size: 10px;
}
.year-card.active .year {
  color: #57b8ff;
}
.year-card.success .check {
  border-color: #7dce82;
  color: #7dce82;
}
.year-card.success .check i {
  color: #7dce82;
}
.year-card.warning .check {
  border-color: #ff606a;
  color: #ff606a;
}
.year-card.warning .check i {
  color: #7dce82;
}

.flatpickr-day.hidden {
  display: block;
}

.calendar-multi-month {
  display: block !important;
  position: relative;
}
.calendar-multi-month .flatpickr-calendar {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.calendar-multi-month .flatpickr-input {
  visibility: hidden;
}
.calendar-multi-month.calendar-multi-month-visible-input .flatpickr-input {
  visibility: visible;
}
.calendar-multi-month .flatpickr-calendar, .calendar-multi-month .flatpickr-days {
  width: 620px !important;
}
.calendar-multi-month .flatpickr-day.startRange {
  border-radius: 50px;
}
.calendar-multi-month .flatpickr-day.startRange:before {
  content: " ";
  display: block;
  position: absolute;
  height: calc(100% + 2px);
  width: calc(50% + 2px);
  background: #CEEBFF;
  top: -1px;
  right: -6px;
  z-index: -1;
}
.calendar-multi-month .flatpickr-day.inRange {
  background: #CEEBFF;
  box-shadow: -5px 0 0 #CEEBFF, 5px 0 0 #CEEBFF;
  border-color: #CEEBFF;
}
.calendar-multi-month .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #CEEBFF, 5px 0 0 #CEEBFF;
  box-shadow: -2px 0 0 #CEEBFF, 5px 0 0 #CEEBFF;
}
.calendar-multi-month .flatpickr-day.endRange {
  border-radius: 50px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.calendar-multi-month .flatpickr-day.endRange:before {
  content: " ";
  display: block;
  position: absolute;
  height: calc(100% + 2px);
  width: calc(50% + 2px);
  background: #CEEBFF;
  top: -1px;
  left: -6px;
  z-index: -1;
}
.calendar-multi-month .dayContainer:first-of-type {
  border-right: 1px solid #999baa;
}

.fc-daygrid-event, .fc-daygrid-dot-event, .fc-daygrid-dot-event:hover {
  color: #ffffff !important;
  cursor: pointer;
}
.fc-daygrid-event.booking_slot, .fc-daygrid-dot-event.booking_slot, .fc-daygrid-dot-event:hover.booking_slot {
  background-color: #7dce82;
  border-color: #7dce82;
}
.fc-daygrid-event.booking, .fc-daygrid-dot-event.booking, .fc-daygrid-dot-event:hover.booking {
  background-color: #57b8ff;
  border-color: #57b8ff;
}
.fc-daygrid-event.linked-booking, .fc-daygrid-dot-event.linked-booking, .fc-daygrid-dot-event:hover.linked-booking {
  background-color: #005BA8;
  border-color: #005BA8;
}
.fc-daygrid-event.booking_to_approve, .fc-daygrid-dot-event.booking_to_approve, .fc-daygrid-dot-event:hover.booking_to_approve {
  background-color: #8C76B2;
  border-color: #8C76B2;
}
.fc-daygrid-event.booking_in_option, .fc-daygrid-dot-event.booking_in_option, .fc-daygrid-dot-event:hover.booking_in_option {
  background-color: #ff8360;
  border-color: #ff8360;
}
.fc-daygrid-event.unavailable, .fc-daygrid-dot-event.unavailable, .fc-daygrid-dot-event:hover.unavailable {
  background-color: #53566f;
  border-color: #53566f;
}
.fc-daygrid-event.booked_ical, .fc-daygrid-dot-event.booked_ical, .fc-daygrid-dot-event:hover.booked_ical {
  background-color: #53566f;
  border-color: #53566f;
}
.fc-daygrid-event.partially_booked, .fc-daygrid-dot-event.partially_booked, .fc-daygrid-dot-event:hover.partially_booked {
  background-color: #f6cc16;
  border-color: #f6cc16;
}
.fc-daygrid-event.partially_booked .fc-event-main, .fc-daygrid-dot-event.partially_booked .fc-event-main, .fc-daygrid-dot-event:hover.partially_booked .fc-event-main {
  color: #53566f;
}
.fc-daygrid-event .fc-daygrid-event-dot, .fc-daygrid-dot-event .fc-daygrid-event-dot, .fc-daygrid-dot-event:hover .fc-daygrid-event-dot {
  display: none;
}

.fc-bg-event.midweek {
  background-color: #ffffff !important;
  opacity: 1 !important;
}
.fc-bg-event.weekend {
  background-color: #fdf9ef !important;
  opacity: 1 !important;
}
.fc-bg-event.vacation {
  background-color: #f9e3e3 !important;
  opacity: 1 !important;
}
.fc-bg-event.has-conflict:before {
  content: "\f33a";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  color: #ff606a;
  position: absolute;
  top: 4px;
  left: 4px;
}
.fc-bg-event.unavailable {
  background-color: transparent !important;
  opacity: 1 !important;
}

.timepickr.flatpickr-input {
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.noCalendar {
  box-shadow: none !important;
  width: 100%;
}
.noCalendar .flatpickr-time {
  border: 1px solid #e5e6e9;
}

.swal2-popup {
  padding: 0;
  overflow: hidden;
}

.swal2-html-container {
  margin: 0 !important;
  padding: 0;
  max-height: none !important;
}
.swal2-html-container .event-title {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #E9EBEA;
  margin-bottom: 0 !important;
}
.swal2-html-container .event-content {
  padding: 20px;
}
.swal2-html-container .event-date-title {
  text-transform: uppercase;
  display: block;
  font-weight: bold;
}
.swal2-html-container .event-reservation-link {
  padding: 20px;
  background-color: #FAFCFE;
  text-align: right;
}
.swal2-html-container .event-reservation-link a {
  margin: 0 !important;
}

.months-container {
  flex-wrap: wrap;
  padding: 20px;
}

.day.day-start.day-half .day-content,
.day.day-end.day-half .day-content {
  background: linear-gradient(135deg, transparent 47%, rgb(120, 120, 120) 49%, transparent 51%, transparent);
}

.year-calendar-modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}
.year-calendar-modal .modal-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.year-calendar-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.year-calendar-modal .modal-title {
  margin: 0;
}
.year-calendar-modal .close {
  cursor: pointer;
}
.year-calendar-modal .modal-body {
  padding-bottom: 10px;
}
.year-calendar-modal .modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
.year-calendar-modal .btn {
  cursor: pointer;
  padding: 8px 16px;
  margin-left: 8px;
  border-radius: 5px;
}
.year-calendar-modal .btn-primary {
  background-color: #007bff;
  color: #fff;
}
.year-calendar-modal .btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.form-select-wrapper {
  width: 300px;
}

#loaderWrapper {
  position: relative;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  border-radius: calc(0.475rem - 1px) calc(0.475rem - 1px) 0 0;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #ff8360;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#dailyOvernightStatisticsWidget .grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 16px 16px;
  grid-template-areas: "title min-30 plus-30";
}
#dailyOvernightStatisticsWidget .grid-layout .title {
  grid-area: title;
}
#dailyOvernightStatisticsWidget .grid-layout .min-30 {
  grid-area: min-30;
}
#dailyOvernightStatisticsWidget .grid-layout .plus-30 {
  grid-area: plus-30;
}
#dailyOvernightStatisticsWidget .grid-layout .grid-span-2 {
  grid-column: 2/span 2;
}
#dailyOvernightStatisticsWidget .statistics-headers .min-30, #dailyOvernightStatisticsWidget .statistics-headers .plus-30 {
  text-align: center;
}
#dailyOvernightStatisticsWidget .switch-toggle-expand {
  background-color: #f5f8fa;
  border-radius: 10px;
  padding: 2px;
  display: inline-flex;
  user-select: none;
  font-weight: bold;
  font-size: 16px;
}
#dailyOvernightStatisticsWidget .switch-toggle-expand button {
  border: none;
  background-color: transparent;
  color: #53566f;
  padding: 4px 10px;
  border-radius: 9px;
  font-weight: 500;
  font-size: 13px;
}
#dailyOvernightStatisticsWidget .switch-toggle-expand button.active {
  background-color: white;
  color: #ff8360;
}
#dailyOvernightStatisticsWidget .daily-overnight-element {
  border-bottom: 1px solid #e5e6e9;
  padding: 30px 0;
}
#dailyOvernightStatisticsWidget .daily-overnight-element:last-of-type {
  border-bottom: none;
}
#dailyOvernightStatisticsWidget .daily-overnight-element h4:first-letter {
  text-transform: capitalize;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .form-check-input:checked {
  background-color: #ff8360;
  border-color: #ff8360;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .locked {
  opacity: 0.6;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .locked.calculation-sum-number-field:after {
  background-color: #999baa !important;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .locked input {
  background-color: #f5f8fa !important;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .sub-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .sub-header.unaknowledged {
  display: none;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .sub-header b {
  color: #999baa;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .sub-header i {
  font-style: italic;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .sub-header p {
  color: #ff8360;
  font-size: 11px;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .calculation-sum-number-field {
  position: relative;
  margin-top: 16px;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .calculation-sum-number-field:before {
  content: " ";
  position: absolute;
  top: -32px;
  left: calc(50% - 1px);
  display: block;
  height: 32px;
  width: 2px;
  border-left: 2px dashed #e5e6e9;
}
#dailyOvernightStatisticsWidget .daily-overnight-element .calculation-sum-number-field:after {
  content: "+";
  position: absolute;
  top: -23px;
  left: calc(50% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8360;
  color: #ffffff;
  width: 14px;
  height: 14px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 2px;
}

.dynamicCostsFormWidget .table-responsive {
  overflow: inherit;
}
.dynamicCostsFormWidget .table-finalise .table i {
  font-style: normal;
}
.dynamicCostsFormWidget .multiselect {
  width: 250px !important;
  position: relative;
  height: 46px;
}
.dynamicCostsFormWidget .multiselect .multiselect-clear, .dynamicCostsFormWidget .multiselect .multiselect-caret {
  position: absolute;
  right: 0;
  top: calc(50% - 7px);
}
.dynamicCostsFormWidget .multiselect .multiselect-clear + .multiselect-caret {
  display: none;
}
.dynamicCostsFormWidget .multiselect .multiselect-option.is-selected {
  background: #57b8ff;
}
.dynamicCostsFormWidget .btn-add-row {
  border: 1px solid #57b8ff !important;
  background-color: transparent;
  color: #57b8ff;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.dynamicCostsFormWidget .btn-add-row:hover {
  background-color: #e0f2ff;
}
.dynamicCostsFormWidget .btn-add-row i {
  color: #57b8ff;
  font-size: 8px;
}

.color-select {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.color-select input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.color-select .checkmark {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  color: #6c757d;
}
.color-select .color-block {
  border-radius: 6px;
  width: 50px;
  height: 50px;
  display: block;
  margin-right: 0.5em;
  border: 2px solid #e5e6e9;
}
.color-select .color-block:before {
  position: absolute;
  display: block;
  content: " ";
  width: 46px;
  height: 46px;
  border-radius: 4px;
  border: 2px solid #ffffff;
}
.color-select .color-block:after {
  content: "\f26e";
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  justify-content: center;
  display: none;
  align-items: center;
  height: 100%;
  font-size: 20px;
}
.color-select input:checked ~ .checkmark {
  font-weight: bold;
}
.color-select input:checked ~ .checkmark .color-block {
  border: 2px solid #57b8ff;
}
.color-select input:checked ~ .checkmark .color-block:after {
  display: flex;
}
.color-select.white .color-block {
  background-color: white;
}
.color-select.green .color-block {
  background-color: #7dce82;
}
.color-select.gray .color-block {
  background-color: black;
}
.color-select.yellow .color-block {
  background-color: #f6cc16;
}

.legend .legend-item {
  padding: 4px 0;
  position: relative;
  display: flex;
  align-items: center;
}
.legend .legend-event.booking_slot:before {
  background-color: #7dce82;
}
.legend .legend-event.booked:before {
  background-color: #57b8ff;
}
.legend .legend-event.booking-linked:before {
  background-color: #005BA8;
}
.legend .legend-event.booking_to_approve:before {
  background-color: #8C76B2;
}
.legend .legend-event.booking_in_option:before {
  background-color: #ff8360;
}
.legend .legend-event.unavailable:before {
  background-color: #53566f;
}
.legend .legend-event.partially_booked:before {
  background-color: #f6cc16;
}
.legend .legend-event:before {
  content: " ";
  display: block;
  width: 20px;
  height: 15px;
  border-radius: 6px;
  margin-right: 8px;
}
.legend .legend-bg-event.unavailable:before {
  background-color: #EEF2F5;
}
.legend .legend-bg-event.weekend:before {
  background-color: #fdf9ef;
}
.legend .legend-bg-event.vacation:before {
  background-color: #f9e3e3;
}
.legend .legend-bg-event:before {
  content: " ";
  display: block;
  width: 20px;
  height: 15px;
  margin-right: 8px;
}

.rates-min-days-grid .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
}

.check-in-check-out-times td.buffer {
  vertical-align: middle !important;
  text-align: center;
}
.check-in-check-out-times td.buffer.warning {
  color: red;
}
.check-in-check-out-times td > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.check-in-check-out-times td > div .form-select {
  max-width: 115px;
}
.check-in-check-out-times td > div .form-select:first-of-type {
  margin-right: 5px;
}
.check-in-check-out-times td > div .form-select:last-of-type {
  margin-left: 5px;
}

.mini-menu {
  position: absolute;
  z-index: 50;
  top: calc(100% + 8px);
  right: 0;
  width: 300px;
  height: auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #f3f3f4;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.1);
  text-align: left;
}

.accommodation-teaser .card {
  overflow: hidden;
}
.accommodation-teaser .card-body {
  margin-left: 250px;
}
.accommodation-teaser .card-body h2 {
  color: #1a1e40;
}
.accommodation-teaser .card-body small {
  color: #53566f;
}
.accommodation-teaser .card-image {
  position: absolute;
  height: 100%;
  width: 250px;
  left: 0;
  top: 0;
}
.accommodation-teaser .card-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.accommodation-teaser .card-image .image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f5f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accommodation-teaser .card-image .image-placeholder i {
  font-size: 25px;
}
.accommodation-teaser .accommodation-teaser-body {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 0.5fr;
  gap: 0px 0px;
  grid-template-areas: "teaser-name teaser-status" "teaser-linked .";
  height: 100%;
}
.accommodation-teaser .teaser-name {
  grid-area: teaser-name;
}
.accommodation-teaser .teaser-status {
  grid-area: teaser-status;
  text-align: right;
}
.accommodation-teaser .teaser-linked {
  grid-area: teaser-linked;
}

.domain-context-widget {
  cursor: pointer;
  user-select: none;
}
.domain-context-widget .domain-context-menu {
  position: absolute;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  margin-top: 8px;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.1);
}
.domain-context-widget .domain-context-item {
  margin-bottom: 4px;
  color: #1a1e40;
}
.domain-context-widget .domain-context-item:hover {
  color: #57b8ff;
}

.stat-option {
  color: #ff8360 !important;
  background-color: #ffeee9 !important;
}

.stat-approvalPending {
  color: #8C76B2 !important;
  background-color: #f0ecf7 !important;
}

.stat-finalising {
  color: #57b8ff !important;
  background-color: #e0f2ff !important;
}

.stat-planned {
  color: rgb(40, 167, 69) !important;
  background-color: rgb(232, 255, 243) !important;
}

.transaction.transaction-debit {
  color: #7dce82;
}
.transaction.transaction-credit {
  color: #ff606a;
}

.booking-event-asset {
  display: flex;
  align-items: center;
}
.booking-event-asset svg {
  width: 20px;
}
.booking-event-asset a {
  margin-left: 8px;
}

.alert.alert-optional {
  background-color: #ffffff;
  border: 1px solid #BCE0FD;
}
.alert.alert-optional .btn-optional {
  color: #57b8ff;
}
.alert.alert-optional .btn-optional:hover {
  background-color: #D5EDFF;
}

.modal-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(26, 30, 64, 0.16);
  display: none;
}
@media (min-width: 768px) {
  .modal-wrapper {
    align-items: center;
  }
}
.modal-wrapper.modal-open {
  display: flex;
}
.modal-wrapper.modal-open .login-requirement-modal {
  background-color: #FDF6D6;
}
.modal-wrapper .click-outside-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
}

.export-nights-per-booking-modal {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -100%);
  height: auto;
  width: auto;
}

.modal-wrapper.modal-open .modal {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 30px;
  z-index: 999999;
}
@media (min-width: 768px) {
  .modal-wrapper.modal-open .modal {
    margin: 10%;
  }
}
.modal-wrapper.modal-open .modal .btn-close-modal {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.modal-wrapper.modal-open .modal .btn-close-modal i {
  transition: transform 0.325s ease-in-out;
  display: block;
  color: #1a1e40;
}
.modal-wrapper.modal-open .modal .btn-close-modal:hover i {
  transform: rotate(90deg);
}

.modal.show {
  position: fixed;
  background-color: #ffffff;
  height: 90%;
  width: 100%;
  border-radius: 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal.show .modal-content {
  flex-grow: 1;
  box-shadow: none !important;
}
@media (min-width: 768px) {
  .modal.show {
    max-width: 600px;
  }
}
.modal.show .btn-close-modal {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.modal.show .btn-close-modal i {
  transition: transform 0.325s ease-in-out;
  display: block;
  color: #1a1e40;
}
.modal.show .btn-close-modal:hover i {
  transform: rotate(90deg);
}
.modal.show .scrollable {
  padding-right: 12px;
  margin-right: -16px;
}

.confirm-delete-modal.show {
  height: 20%;
  width: 100%;
  border-radius: 15px;
}
@media (min-width: 768px) {
  .confirm-delete-modal.show {
    max-width: 400px;
  }
}

.login-requirement-modal {
  background-color: #FDF6D6;
  z-index: 0;
  overflow: hidden;
}
.login-requirement-modal:before {
  content: " ";
  background: #ffffff;
  width: 200%;
  padding-bottom: 100%;
  left: -50%;
  top: 30%;
  border-radius: 50%;
  display: block;
  position: absolute;
  transition: transform 1s ease;
  z-index: -1;
}
.login-requirement-modal .login-requirment-icon {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  background-color: #FAE484;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.login-requirement-modal .login-requirment-icon svg {
  width: 80px;
  height: 80px;
}

.modal.contact-manager-modal {
  height: 80vh;
  overflow: hidden;
  position: relative;
}
.modal.contact-manager-modal #js-ivv-warning {
  color: #ff606a;
}
@media (max-width: 991.98px) {
  .modal.contact-manager-modal {
    border-radius: 30px 30px 0 0 !important;
    padding: 20px !important;
  }
  .modal.contact-manager-modal h4 {
    text-align: center;
  }
  .modal.contact-manager-modal .btn-close-modal {
    right: 10px !important;
  }
  .modal.contact-manager-modal .scrollable {
    padding-bottom: 100px;
  }
}
.modal.contact-manager-modal .scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
  height: 100%;
}

.stepper-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-wrapper .stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
@media (max-width: 768px) {
  .stepper-wrapper .stepper-item {
    font-size: 12px;
  }
}
.stepper-wrapper .stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 7px solid #BCE0FD;
  width: 100%;
  top: calc(17px / 2 - 7px / 2);
  left: -50%;
  z-index: 2;
}
.stepper-wrapper .stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 7px solid #BCE0FD;
  width: 100%;
  top: calc(17px / 2 - 7px / 2);
  left: 50%;
  z-index: 2;
}
.stepper-wrapper .stepper-item:first-child::before {
  content: none;
}
.stepper-wrapper .stepper-item:last-child::after {
  content: none;
}
.stepper-wrapper .stepper-item.active .step-dot, .stepper-wrapper .stepper-item.completed .step-dot {
  background-color: #57b8ff;
  width: 30px;
  height: 30px;
  margin-top: calc((30px - 17px) / 2 * -1);
}
.stepper-wrapper .stepper-item.active .step-name, .stepper-wrapper .stepper-item.completed .step-name {
  margin-top: 0;
  color: #57b8ff;
}
.stepper-wrapper .stepper-item.completed::before {
  position: absolute;
  content: "";
  border-bottom: 7px solid #57b8ff;
  width: 100%;
  top: calc(17px / 2 - 7px / 2);
  left: 50%;
  z-index: 3;
}
.stepper-wrapper .stepper-item .step-name {
  margin-top: calc((30px - 17px) / 2);
  font-weight: bolder;
  color: #BCE0FD;
  font-size: 14px;
}
.stepper-wrapper .stepper-item .step-dot {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #BCE0FD;
  margin-bottom: 6px;
}

.profile-image-placeholder {
  background-color: #57b8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  transition: border-radius 0.325s ease-in;
}
.profile-image-placeholder:hover {
  border-radius: 14px;
}

.kampas-logo svg {
  width: 46px;
}
@media (min-width: 768px) {
  .kampas-logo svg {
    width: 187px;
  }
}

.dropzone .dropzone-select {
  border: 1px dashed #009ef7;
  background-color: #ecf8ff;
  padding: 1.5rem 1.75rem;
  border-radius: 0.475rem !important;
}

.draggable {
  cursor: move;
}

.js_remove-zone {
  border: 2px dashed #e5e6e9;
  border-radius: 8px;
  height: 75px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.js_remove-zone .file-image {
  display: none;
}
.js_remove-zone i {
  font-size: 20px;
}

.file-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px 8px;
}
@media (min-width: 992px) {
  .file-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.file-grid .file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 30px 20px;
  border: 1px solid #57b8ff;
  border-radius: 0.475rem;
  color: #57b8ff;
  position: relative;
  aspect-ratio: 1;
}
.file-grid .file .file-type {
  color: #57b8ff;
}
.file-grid .file .file-options .btn-remove {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 10;
  background-color: #ff606a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.file-grid .file .file-options .btn-remove i {
  color: #ffffff;
}
.file-grid .file .file-options .download-mark {
  background-color: rgb(0, 138.5714285714, 240);
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 10;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.file-grid .file .file-options .download-mark i {
  color: #ffffff;
}
.file-grid .file:hover {
  color: rgb(0, 138.5714285714, 240);
}
.file-grid .file:hover .file-type {
  color: rgb(0, 138.5714285714, 240);
}
.file-grid .file:hover .btn-remove, .file-grid .file:hover .download-mark {
  display: flex;
}
.file-grid .file-image {
  border-radius: 0.68rem;
  position: relative;
  cursor: move;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-grid .file-image:hover .btn-handle, .file-grid .file-image:hover .btn-remove-upload {
  opacity: 1;
}
.file-grid .file-image .btn-handle, .file-grid .file-image .btn-remove-upload {
  background-color: #ffffff;
  color: #53566f;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 0;
}
.file-grid .file-image .btn-handle i, .file-grid .file-image .btn-remove-upload i {
  padding: 0;
  margin: 0;
}
.file-grid .file-image .btn-remove-upload {
  margin-top: 8px;
}
.file-grid .file-image .btn-remove-upload i {
  color: #ff606a;
}
.file-grid .file-image .btn-remove-upload:hover {
  background-color: #ffe9eb;
}
.file-grid .file-image .btn-handle {
  position: absolute;
  top: calc(50% - 19px - 15px);
  left: calc(50% - 15px);
}
.file-grid .file-image .image-container {
  border: 3px solid transparent;
  border-radius: 0.68rem;
  overflow: hidden;
}
.file-grid .file-image .image-container img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}
.file-grid .file-image .cover-tag {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 10;
  padding: 0 8px;
  background-color: #57b8ff;
  border-radius: 12px;
  width: auto;
  height: 25px;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.file-grid .file-image .btn-remove {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 10;
  background-color: #ff606a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.file-grid .file-image .btn-remove i {
  color: #ffffff;
}
.file-grid .file-image:first-of-type .cover-tag {
  display: flex;
}
.file-grid .file-image.selected .image-container {
  border: 5px solid #57b8ff;
}
.file-grid .file-image.file-image-select {
  cursor: pointer;
}
.file-grid .file-image.file-image-select .cover-tag {
  display: none;
}
.file-grid .file-image.file-image-select .cover-tag.selected {
  display: flex;
}
.file-grid .file-image:hover .image-container {
  border: 3px solid #57b8ff;
}
.file-grid .file-image:hover .btn-remove {
  display: flex;
}

.boxed-icon {
  height: 32px;
  min-width: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F8FA;
  color: #ffffff;
}
.boxed-icon i {
  font-size: 16px;
}
.boxed-icon.small {
  height: 30px;
  width: 30px;
}
.boxed-icon.medium {
  height: 40px;
  width: 40px;
}
@media (min-width: 1200px) {
  .boxed-icon.xl-medium {
    height: 40px;
    width: 40px;
  }
}
.boxed-icon.large {
  height: 80px;
  width: 80px;
  border-radius: 24px;
}
.boxed-icon.large i {
  font-size: 40px;
}
.boxed-icon.darken i {
  color: rgba(26, 30, 64, 0.4) !important;
}
.boxed-icon.green {
  background-color: #7dce82;
}
.boxed-icon.green i {
  color: #ffffff;
}
.boxed-icon.lightgreen {
  background-color: #f2faf2;
}
.boxed-icon.lightgreen i {
  color: #7dce82;
}
.boxed-icon.blue {
  background-color: #57b8ff;
}
.boxed-icon.blue i {
  color: #ffffff;
}
.boxed-icon.lightblue {
  background-color: #e0f2ff;
}
.boxed-icon.lightblue i {
  color: #57b8ff;
}
.boxed-icon.orange {
  background-color: #ff8360;
}
.boxed-icon.orange i {
  color: #ffffff;
}
.boxed-icon.lightorange {
  background-color: #ffeee9;
}
.boxed-icon.lightorange i {
  color: #ff8360;
}
.boxed-icon.red {
  background-color: #ff606a;
}
.boxed-icon.red i {
  color: #ffffff;
}
.boxed-icon.lightred {
  background-color: #ffe9eb;
}
.boxed-icon.lightred i {
  color: #ff606a;
}
.boxed-icon.yellow {
  background-color: #fcedad;
}
.boxed-icon.yellow i {
  color: #ffffff;
}
.boxed-icon.lightyellow {
  background-color: #fef9e6;
}
.boxed-icon.lightyellow i {
  color: #f6cc16;
}
.boxed-icon.lightpurple {
  background-color: #f0ecf7;
}
.boxed-icon.lightpurple i {
  color: #8C76B2;
}

.textfield-with-icon {
  position: relative;
}
.textfield-with-icon input {
  padding-right: 40px;
}
.textfield-with-icon i {
  position: absolute;
  font-size: 14px;
  top: calc(50% - 7px);
  right: 20px;
  width: 1px;
}

.textfield-with-front-icon {
  position: relative;
}
.textfield-with-front-icon input {
  padding-left: 40px;
}
.textfield-with-front-icon i {
  position: absolute;
  font-size: 14px;
  top: calc(50% - 7px);
  left: 20px;
  width: 1px;
}

.form-group.horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-group.horizontal input, .form-group.horizontal .invalid-feedback {
  width: 200px;
}

.form-control, .form-select {
  display: block;
  width: 100%;
}
.form-control:disabled, .form-select:disabled {
  background: unset;
  opacity: 0.7;
}
.form-control:disabled .form-select.form-select-solid, .form-select:disabled .form-select.form-select-solid {
  background-color: red;
}
.form-control:hover:disabled, .form-select:hover:disabled {
  background: unset;
}

.select2-container--disabled .form-select.form-select-solid {
  background-color: unset;
  cursor: unset;
}
.select2-container--disabled .form-select {
  background-image: unset;
}
.select2-container--disabled .select2-selection__clear {
  display: none;
}

.form-without-group-margin .mb-3 {
  margin-bottom: 0 !important;
}

.contact-form-select-element .ts-control:after {
  content: "\e90f";
  font-family: "icomoon";
  font-size: 10px;
  margin-left: 8px;
  position: absolute;
  right: 0;
}

.review-element {
  background-color: #fef9e6;
  border: 1px solid #FCEDAD;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  container-type: inline-size;
}
.review-element.reply {
  margin-top: 1em;
  margin-left: min(15vw, 130px);
}
.review-element.reply:before {
  content: " ";
  display: block;
  position: absolute;
  top: calc(-1em - 1px);
  left: -50px;
  width: 50px;
  height: calc(0.5em + 50% - 1px);
  border-left: 2px solid #FCEDAD;
  border-bottom: 2px solid #FCEDAD;
  border-bottom-left-radius: 40px;
}
.review-element h2 {
  margin-bottom: 0.5em;
}
.review-element .review-title a {
  text-decoration: none;
  color: unset;
  font-size: unset;
  line-height: unset;
}
.review-element .rating-element {
  background-color: white;
  box-shadow: 0 6px 12px rgba(26, 30, 64, 0.08);
  padding: 8px 16px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  margin-bottom: 1.5em;
  font-size: clamp(0.7rem, 2cqw, 1rem);
}
@-moz-document url-prefix() {
  .review-element .rating-element {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .review-element .rating-element {
    flex-direction: row;
  }
}
.review-element .rating-element .title {
  color: #999BAA;
  font-weight: bold;
  text-transform: uppercase;
}
.review-element p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 1em;
}
.review-element .remarks {
  padding: 0;
}
.review-element .remarks li {
  font-size: 16px;
  padding: 10px 0;
  display: flex;
  align-items: self-start;
}
.review-element .remarks li:before {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  margin-right: 1em;
  margin-top: 4px;
}
.review-element .remarks li.positive:before {
  content: "\e925";
  color: #7dce82;
}
.review-element .remarks li.negative:before {
  content: "\e920";
  color: #ff606a;
}

.rating {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.rating i {
  font-size: 16px;
  margin-right: 0.5em;
}

.prosFields label, .consFields label {
  display: none !important;
}
.prosFields .field .form-group:first-child, .consFields .field .form-group:first-child {
  display: flex;
  align-items: center;
  width: 100%;
}
.prosFields .field .form-group:first-child > div, .consFields .field .form-group:first-child > div {
  width: 100%;
}
.prosFields .field .form-group:first-child > div .mb-3, .consFields .field .form-group:first-child > div .mb-3 {
  margin-bottom: 0 !important;
}
.prosFields .field .form-group:first-child:before, .consFields .field .form-group:first-child:before {
  font-family: "icomoon";
  font-size: 20px;
  margin-right: 1em;
}

.prosFields .field .form-group:first-child:before {
  content: "\e925";
  color: #7dce82;
}

.consFields .field .form-group:first-child:before {
  content: "\e920";
  color: #ff606a;
}

.conflict .btn-re-add, .conflict .btn-dismiss, .conflict .fa-spinner {
  display: none;
}
.conflict.loading .fa-spinner {
  display: inline-block;
}
.conflict.loading .btn-re-add, .conflict.loading .btn-dismiss {
  display: none !important;
}
.conflict.new .bullet {
  background-color: #ff606a;
}
.conflict.new .btn-dismiss {
  display: inline-block;
}
.conflict.dismissed .btn-re-add {
  display: inline-block;
}

.vertical-stepper-component .vertical-stepper-item {
  margin-top: 13px;
  margin-bottom: 13px;
}
.vertical-stepper-component .vertical-stepper-item:first-of-type {
  margin-top: 0;
}
.vertical-stepper-component .vertical-stepper-item:last-of-type {
  margin-bottom: 0;
}
.vertical-stepper-component .vertical-stepper-item:last-of-type .vertical-stepper-content:before {
  display: none;
}
.vertical-stepper-component .vertical-stepper-item.current .vertical-stepper-header .vertical-stepper-symbol {
  background-color: #ff8360;
}
.vertical-stepper-component .vertical-stepper-item.current .vertical-stepper-header label {
  color: #53566f;
}
.vertical-stepper-component .vertical-stepper-item.current .vertical-stepper-content {
  padding-bottom: 100px;
}
.vertical-stepper-component .vertical-stepper-item.completed .vertical-stepper-header .vertical-stepper-symbol {
  background-color: #ff8360;
}
.vertical-stepper-component .vertical-stepper-item.completed .vertical-stepper-header label {
  color: #ff8360;
}
.vertical-stepper-component .vertical-stepper-item.completed .vertical-stepper-content:before {
  background-color: #ff8360;
}
.vertical-stepper-component .vertical-stepper-item .vertical-stepper-header {
  display: flex;
  justify-content: space-between;
}
.vertical-stepper-component .vertical-stepper-item .vertical-stepper-header .vertical-stepper-symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #E5E6E9;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
}
.vertical-stepper-component .vertical-stepper-item .vertical-stepper-header label {
  margin-left: 18px;
  color: #999BAA;
  font-weight: bold;
  font-size: 18px;
}
.vertical-stepper-component .vertical-stepper-item .vertical-stepper-header .vertical-stepper-prev-btn {
  background-color: #E5E6E9;
  color: #53566f;
}
.vertical-stepper-component .vertical-stepper-item .vertical-stepper-content {
  position: relative;
  min-height: 50px;
  width: 100%;
  margin-top: 13px;
  padding-left: 48px;
}
.vertical-stepper-component .vertical-stepper-item .vertical-stepper-content:before {
  position: absolute;
  top: 0;
  left: 14px;
  content: " ";
  display: block;
  width: 2px;
  height: 100%;
  background-color: #E5E6E9;
}
.vertical-stepper-component .vertical-stepper-item .vertical-stepper-content .vertical-stepper-next-btn {
  background-color: #ff8360;
  color: #ffffff;
}

.text-counter {
  position: relative;
}
.text-counter:after {
  content: attr(data-counter);
  color: #53566f;
  position: absolute;
  right: 16px;
  bottom: -18px;
  font-size: 14px;
  line-height: 14px;
}

.infobox {
  padding: 4px 12px;
  border-radius: 16px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  white-space: normal;
  overflow-x: auto;
  line-height: 28px;
}
.infobox span {
  overflow: auto;
}
.infobox button.btn {
  float: right;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}
.infobox.green {
  background-color: #f2faf2;
  color: #7dce82;
}
.infobox.blue {
  background-color: #e0f2ff;
  color: #57b8ff;
}
.infobox.red {
  background-color: #ffe9eb;
  color: #ff606a;
}
.infobox.orange {
  background-color: #ffeee9;
  color: #ff8360;
}
.infobox.yellow {
  background-color: #fef9e6;
  color: #f6cc16;
}
.infobox.black {
  background-color: #1a1e40;
  color: #ffffff;
}
.infobox.grey {
  background-color: #e5e6e9;
  color: #1a1e40;
}

.pre-auth {
  background-color: #ffffff;
}
.pre-auth .background-image {
  height: 100vh;
  object-fit: cover;
  display: none;
}
@media (min-width: 992px) {
  .pre-auth .background-image {
    display: block;
    width: calc(100% - 500px);
  }
}
.pre-auth.login-page, .pre-auth.password-page, .pre-auth.registration-page {
  padding: 0;
}
.pre-auth.login-page, .pre-auth.password-page {
  background-color: #f2faf2;
}
.pre-auth.login-page .link-forgot, .pre-auth.password-page .link-forgot {
  font-size: 12px;
  line-height: 32px;
}
.pre-auth.login-page .alert, .pre-auth.password-page .alert {
  padding: 6px;
  color: #418ABF;
  margin: 8px 0;
}
.pre-auth.registration-page {
  background-color: #e0f2ff;
}
.pre-auth.registration-page .invalid-feedback {
  margin-top: -15px;
  color: #ff606a;
}
.pre-auth small {
  color: #999baa;
}
.pre-auth .background-pre-auth {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.pre-auth .card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.pre-auth .card-wrapper .card {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 8px 48px rgba(26, 30, 64, 0.12);
  border-radius: 12px;
  padding: 24px;
}
.pre-auth .card-wrapper .card img {
  height: 100px;
  margin: 0 auto;
  display: block;
}
@media (min-width: 768px) {
  .pre-auth .card-wrapper .card {
    padding: 48px;
    max-width: 600px;
  }
}

.characteristics-table .label {
  width: 35%;
  vertical-align: middle;
}
.characteristics-table .unit-field input {
  padding-right: 50px;
  background-image: none !important;
}
.characteristics-table .unit-field .text-field-unit {
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #1a1e40;
}

.error-page svg {
  max-width: min(80vw, 400px);
}

.error-code {
  font-size: 33vw;
  line-height: 33vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: rgb(222, 238, 246);
  z-index: -1;
  font-family: "Bowlby One", sans-serif;
}

.invalid-feedback {
  color: #ff606a;
  padding: 4px 8px;
  margin-bottom: 16px;
  position: relative;
}

.registration-complete .container {
  height: 100%;
}
.registration-complete .registration-complete-container {
  height: calc(100% - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.registration-complete .registration-complete-container .gif {
  width: 200px;
}
.registration-complete .registration-count-down-redirect {
  font-weight: bold;
  font-size: 30px;
}

.stepper-nav .final .stepper-icon .stepper-check {
  color: #009ef7 !important;
  display: block !important;
}
.stepper-nav .final .stepper-title {
  color: #3f4254 !important;
}
.stepper-nav .final .stepper-desc {
  color: #a1a5b7 !important;
}
.stepper-nav .final.done .stepper-icon {
  background-color: #009ef7 !important;
}
.stepper-nav .final.done .stepper-icon .stepper-check {
  color: white !important;
}
.stepper-nav .final.done .stepper-title {
  color: #7e8299 !important;
}
.stepper-nav .final.done .stepper-desc {
  color: #b5b5c3 !important;
}

.password-reset-container img {
  width: 150px;
  display: block;
  margin: 6.5em auto 0;
}

.c-admin-content .card {
  margin: 1rem 1rem 3rem 1rem;
}
.c-admin-content .card-header {
  justify-content: space-between;
  align-items: center;
}
.c-admin-content .card-header .card-toolbar {
  margin-left: auto;
}

.card-header {
  row-gap: 10px;
}
@media (max-width: 599px) {
  .card-header {
    justify-content: center !important;
  }
}
.card-header .card-more-info {
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.card-header .card-more-info p {
  max-width: 500px;
  margin: 0;
}
@media (max-width: 1240px) {
  .card-header .card-more-info p {
    max-width: 100%;
  }
}
@media (max-width: 599px) {
  .card-header .card-more-info p {
    text-align: center;
  }
}
@media (max-width: 599px) {
  .card-header .card-more-info {
    flex-direction: column;
    row-gap: 10px;
  }
}

.nav-line-tabs .nav-item .nav-link {
  color: unset;
}

.c-admin-content .table th a {
  color: unset;
}
.c-admin-content .table thead {
  border-bottom: 2px solid #DDDDDD;
}
.c-admin-content .table thead th {
  font-weight: bold;
}
.c-admin-content .table tbody th a:hover {
  text-decoration: underline !important;
}
.c-admin-content .table tbody th, .c-admin-content .table tbody td {
  border-right: 1px solid #DDDDDD;
}
.c-admin-content .table tbody th:last-child, .c-admin-content .table tbody td:last-child {
  border-right: unset;
}
.c-admin-content .table.table-striped tr:first-child, .c-admin-content .table.table-striped th:first-child, .c-admin-content .table.table-striped td:first-child {
  padding-left: 0.5rem;
}
.c-admin-content .table.table-striped tr:first-child, .c-admin-content .table.table-striped th:first-child, .c-admin-content .table.table-striped td:first-child {
  padding-left: 0.5rem;
}

.c-property {
  margin-bottom: 0.5rem;
}
.c-property__label {
  font-weight: bold;
}